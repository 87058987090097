import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import { _getLocation, _getLocationByZipCode } from 'controllers/location/location_controller';
import qs from 'qs';
import AnimationLoadingWidthoutParams from './components/AnimationLoading/AnimationLoadingWithoutParams';
import { CATEGORY_LABELS } from '../schola-match-results/utils/getFeatureLabels';

export const question1SchoolType = {
  a: ['public+charter', 'public+district', 'public+magnet'],
  b: ['private'],
  c: ['public+district', 'public+magnet', 'public+charter', 'private'],
  d: ['virtual/online', 'special', 'vocational'],
  // d: ['public+charter', 'public+district', 'public+magnet', 'private', 'special'],
  // e: ['public+charter', 'public+district', 'public+magnet', 'private', 'vocational'],
};

/** subjects: math and science, history and social, art and music, reading and writing */
export const question2Features = {
  a: [69, 182, 285, 72, 71, 74, 229, 92, 219, 252, 90, 91, 215, 236, 551], // math and science
  b: [210, 445, 224, 241, 199, 354, 186, 46, 31, 250, 205, 99, 440, 507, 295, 199, 354, 440], // art and music
  c: [210, 223, 100, 181], // history and social studies
  d: [76, 203, 95, 194, 254, 239, 248], // reading and writing
};
/** learning preferences */
export const question3Features = {
  a: [4, 6, 8, 356, 368, 388, 372, 369], // Doing things hands-on
  b: [15, 387, 403], // Talking and working in groups
  c: [418, 3, 435, 395, 367, 409], // Reading and working independently through problems
  d: [14, 7, 192, 102], // Watching videos and seeing things visually
};
/** extracurricular activities: sports, technology, music, arts etc */
export const question4Features = {
  a: [
    441, 67, 48, 49, 443, 547, 50, 266, 51, 277, 191, 269, 273, 279, 270, 268, 53, 54, 55, 56, 267, 275, 278, 274, 57,
    510, 272, 345, 58, 281, 263, 59, 60, 319, 376, 66, 280, 61, 62, 264, 63, 64, 282, 65, 550, 313,
  ], // sports
  b: [520, 295, 315, 307, 415, 507, 329, 328, 283, 337, 416, 335, 349, 324, 332, 287, 302, 527, 326, 288, 554], // arts and crafts
  c: [322, 438, 544, 373, 301, 545, 289, 293], // technology ot gaming clubs
  d: [311, 285, 292], // academic-focused clubs
  e: [294, 442, 317, 524], // music or drama
  f: [296, 522, 321, 309, 432, 334], // helping in the community
  g: [],
};
/** special needs, support programs, additional offerings */
export const question5Features = {
  a: [103, 105, 506, 111, 106, 107, 108, 109, 110, 112, 113, 114, 115], // specific needs support
  b: [509, 359, 508, 502, 444], // support programs
  c: [424, 364, 536, 417, 537, 370, 521], // additional offerings
  d: [],
};
/** languages needs */
export const question6Features = {
  a: [
    118, 171, 172, 178, 125, 177, 174, 175, 231, 120, 121, 122, 123, 124, 126, 451, 402, 170, 179, 242, 173, 86, 256,
    400, 255, 176, 401, 385, 180, 119,
  ], // languages classes
  b: [343, 357, 540, 404, 444, 514], // english language learning support
  c: [],
};
/** specialized school programs */
export const question7Features = {
  a: [504, 514], // gifted programs
  b: [142, 371], // advanced courses
  c: [557, 327], // JROTC
  d: [],
};
/** school distance expressed on miles, a is very important, c is not */
const question8Radius = {
  a: [2.5],
  b: [10],
  c: [20],
};

export const question9Environment = {
  a: [342, 513, 523, 526, 553], // In-person
  b: [447, 9, 446, 408, 503], // Online - Alternative
  c: [342, 513, 523, 526, 553, 447, 446, 408, 503, 9], // Blended
  d: [342, 513, 447, 9, 446, 408, 503, 523, 526, 553], // All
};

const handleFeatures = (idsForLoop) => {
  let featureLoop = '';
  idsForLoop?.forEach((q) => (featureLoop += q > 0 ? `${q},` : ``));
  return featureLoop;
};

const handleFeaturesLoop = (idsForLoop) => {
  let featureLoop = '';
  idsForLoop?.forEach((q, i) => (featureLoop += q > 0 ? `&f[${i}][i]=${q}&f[${i}][w]=50` : ``));
  return featureLoop;
};

const handleSchoolTypesLoop = (elemScholType) => {
  let schoolTypeLoop = '';
  elemScholType?.forEach((schoolType, i) => (schoolTypeLoop += `school_types[${i}]=${schoolType}&`));
  return schoolTypeLoop;
};

const ScholaMatchv5 = () => {
  const obj = qs.parse(window.location.search);
  const [querySearch, setQuerySearch] = useState(obj);
  const [lat, setLng] = useState(null);
  const [lng, setLat] = useState(null);
  const [address, setAddress] = useState('');
  const [stateUSA, setStateUSA] = useState('');
  const [zipCode, setZipCode] = useState('');

  const handleQueryParams = (queryString) => {
    const {
      grade,
      question1,
      question2,
      question3,
      question4,
      question5,
      question6,
      question7,
      question8,
      question9,
      source,
      leadId,
      fbclid,
      gclid,
    } = queryString;

    let featuresIds = [];
    let schooltypes = [];
    let radius = 10;
    let grades = grade === 'Head Start' ? 'HS' : grade;

    if (question1) {
      schooltypes.push(...question1SchoolType[question1]);
    }
    if (question2) {
      featuresIds.push(...question2Features[question2]);
    }
    if (question3) {
      featuresIds.push(...question3Features[question3]);
    }
    if (question4) {
      // featuresIds.push(...question4Features[question4]);
      question4.split(',').map((answer) => {
        featuresIds.push(...question4Features[answer.trim()]);
      });
    }
    if (question5) {
      question5.split(',').map((answer) => {
        featuresIds.push(...question5Features[answer.trim()]);
      });
    }
    if (question6) {
      question6.split(',').map((answer) => {
        featuresIds.push(...question6Features[answer.trim()]);
      });
    }
    if (question7) {
      question7.split(',').map((answer) => {
        featuresIds.push(...question7Features[answer.trim()]);
      });
    }
    if (question8) {
      radius = question8Radius[question8];
    }
    if (question9) {
      featuresIds.push(...question9Environment[question9]);
    }

    const _source = source ? `&source=${source}` : '';
    const _leadId = leadId ? `&leadId=${leadId}` : '';
    const _fbclid = fbclid ? `&fbclid=${fbclid}` : '';
    const _gclid = gclid ? `&gclid=${gclid}` : '';
    const _externalCampaignId = queryString.external_campaign_id
      ? `&external_campaign_id=${queryString.external_campaign_id}`
      : '';

    return {
      schooltypes: handleSchoolTypesLoop(schooltypes),
      featuresIds: handleFeatures(featuresIds),
      radius,
      grades,
      _source,
      _leadId,
      _fbclid,
      _gclid,
      _externalCampaignId,
    };
  };
  const getLocationByZipCode = async (zip) => {
    try {
      let zipcode = zip;
      if (zip.includes('-')) {
        zipcode = zip.split('-')[0];
      }

      const geo = await _getLocationByZipCode(zipcode);

      let state;
      // validate data results
      if (geo.results && geo.results.length > 0) {
        geo.results[0].address_components.forEach((addressPart) => {
          if (addressPart.types.indexOf('administrative_area_level_1') !== -1) {
            state = addressPart.short_name;
          }
        });

        return {
          address: geo.results[0].formatted_address,
          lat: geo.results[0].geometry.location.lat,
          lng: geo.results[0].geometry.location.lng,
          state,
        };
      }
      console.log('No geolocation found');
    } catch (error) {
      console.log('getLocationByZipCode error:', error);
    }
  };

  // effect to store SM answers on localstorage
  useEffect(() => {
    if (querySearch) {
      let data = {
        parentName: querySearch?.parentName,
        studentName: querySearch?.studentName,
        grade: querySearch?.grade,
        phone: querySearch?.phone,
        email: querySearch?.email,
        formId: querySearch?.formId,
        submissionId: querySearch?.submissionId,
        zipCode: querySearch?.zipCode,
      };
      if (window.freshpaint) {
        window.freshpaint.track('scholamatch', data, { integrations: { All: true } });
      }

      data.answers = {
        [CATEGORY_LABELS.type]: querySearch?.question1,
        [CATEGORY_LABELS.subject]: querySearch?.question2,
        [CATEGORY_LABELS.learningPreferences]: querySearch?.question3,
        [CATEGORY_LABELS.extracurricular]: querySearch?.question4,
        [CATEGORY_LABELS.specialNeeds]: querySearch?.question5,
        [CATEGORY_LABELS.languageNeeds]: querySearch?.question6,
        [CATEGORY_LABELS.specializedPrograms]: querySearch?.question7,
        [CATEGORY_LABELS.distance]: querySearch?.question8,
        [CATEGORY_LABELS.environment]: querySearch?.question9,
      };

      localStorage.setItem('scholamatchinfoV5', JSON.stringify(data));
    }
  }, [querySearch]);

  // effect to process params, geo data response and build the final url
  useEffect(() => {
    const queryParams = handleQueryParams(querySearch);

    if (querySearch.zipCode) {
      getLocationByZipCode(querySearch.zipCode)
        .then((location) => {
          let query = `address=${location.address}&state=${location.state}&lat=${location.lat}&lon=${location.lng}&zip=${querySearch.zipCode}&grades[0]=${queryParams.grades}&ignore=true&${queryParams.schooltypes}radius=${queryParams.radius}&features=${queryParams.featuresIds}&sortBy=distance${queryParams._source}${queryParams._externalCampaignId}${queryParams._leadId}${queryParams._fbclid}${queryParams._gclid}`;
          browserHistory.push(`/schola-match?${query}&scholamatch=v5`);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  return <AnimationLoadingWidthoutParams />;
};

export default ScholaMatchv5;
